export const initializeSecurity = () => {
  // Désactiver le clic droit
  document.addEventListener('contextmenu', (e) => e.preventDefault());

  // Désactiver les raccourcis clavier courants
  document.addEventListener('keydown', (e) => {
    // Désactiver F12
    if (e.key === 'F12') {
      e.preventDefault();
      return false;
    }

    // Désactiver Ctrl+Shift+I/J/C
    if (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C')) {
      e.preventDefault();
      return false;
    }

    // Désactiver Ctrl+U (voir source)
    if (e.ctrlKey && e.key === 'u') {
      e.preventDefault();
      return false;
    }
  });

  // Masquer les console.log en production
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
    console.error = () => {};
  }

  // Détecter et bloquer les outils de développement
  const detectDevTools = () => {
    const widthThreshold = window.outerWidth - window.innerWidth > 160;
    const heightThreshold = window.outerHeight - window.innerHeight > 160;
    
    if (widthThreshold || heightThreshold) {
      document.body.innerHTML = 'Accès non autorisé';
    }
  };

  setInterval(detectDevTools, 1000);
};