import { getPopularProductsAsins, getMultipleProducts } from './api';
import { Product } from '../types/api';
import { transformSearchResults } from '../utils/transformers';

export const fetchPopularProducts = async (): Promise<Product[]> => {
  try {
    const asins = await getPopularProductsAsins();
    if (!asins?.length) {
      console.warn('No ASINs received from popular deals API');
      return [];
    }

    const products = await getMultipleProducts(asins);
    if (!products?.length) {
      console.warn('No products received from multiple products API');
      return [];
    }

    const transformedProducts = transformSearchResults(products);
    if (!transformedProducts?.length) {
      console.warn('No products after transformation');
      return [];
    }

    return transformedProducts.filter(product => 
      product && 
      product.asin && 
      product.name && 
      Object.keys(product.prices).length > 0
    );
  } catch (error) {
    console.error('Error fetching popular products:', error);
    return [];
  }
};