type AffiliateTagMap = {
  'fr': string;
  'de': string;
  'it': string;
  'es': string;
  'co.uk': string;
  'com.be': string;
};

export const AFFILIATE_TAGS: AffiliateTagMap = {
  'fr': 'tastech05-21',
  'de': 'tastech010-21',
  'it': 'tastech03-21',
  'es': 'tastech000-21',
  'co.uk': 'tastech08e-21',
  'com.be': 'tastech02a-21'
};

export const getAmazonLink = (asin: string, country: keyof AffiliateTagMap): string => {
  const tag = AFFILIATE_TAGS[country];
  const domain = country === 'co.uk' ? 'amazon.co.uk' : `amazon.${country}`;
  return `https://www.${domain}/dp/${asin}?tag=${tag}`;
};