import React from 'react';

const BRAND_LOGOS = [
  'https://cdn.worldvectorlogo.com/logos/apple-11.svg',
  'https://cdn.worldvectorlogo.com/logos/samsung-8.svg',
  'https://cdn.worldvectorlogo.com/logos/sony-2.svg',
  'https://cdn.worldvectorlogo.com/logos/lg-electronics-1.svg',
  'https://cdn.worldvectorlogo.com/logos/dell-computer.svg',
  'https://cdn.worldvectorlogo.com/logos/bosch-logo-simple.svg',
  'https://cdn.worldvectorlogo.com/logos/logitech-2-1.svg',
  'https://cdn.worldvectorlogo.com/logos/siemens.svg',
  'https://cdn.worldvectorlogo.com/logos/nvidia-wordmark-1.svg',
  'https://cdn.worldvectorlogo.com/logos/amd-logo-1.svg'
];

export const BrandLogos: React.FC = () => {
  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
        Marques populaires
      </h2>
      <div className="relative overflow-hidden">
        <div className="flex items-center justify-center space-x-8 md:justify-start [&_img]:mx-6 animate-infinite-scroll">
          {[...BRAND_LOGOS, ...BRAND_LOGOS].map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Logo ${index}`}
              className="h-12 w-20 object-contain transition-transform hover:scale-110"
            />
          ))}
        </div>
      </div>
    </div>
  );
};