// import React, { useEffect, useRef, useCallback } from 'react';
// import { motion } from 'framer-motion';
// import { ProductCard } from './ProductCard';
// import { Product } from '../types/api';

// interface ProductListProps {
//   products: Product[];
//   hasNextPage?: boolean;
//   isFetchingNextPage?: boolean;
//   fetchNextPage?: () => void;
// }

// export const ProductList: React.FC<ProductListProps> = ({
//   products,
//   hasNextPage,
//   isFetchingNextPage,
//   fetchNextPage
// }) => {
//   const loadMoreRef = useRef<HTMLDivElement>(null);

//   const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
//     const target = entries[0];
//     if (target.isIntersecting && hasNextPage && !isFetchingNextPage && fetchNextPage) {
//       fetchNextPage();
//     }
//   }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

//   useEffect(() => {
//     const observer = new IntersectionObserver(handleObserver, {
//       root: null,
//       rootMargin: '100px',
//       threshold: 0.1
//     });

//     const currentRef = loadMoreRef.current;
//     if (currentRef) {
//       observer.observe(currentRef);
//     }

//     return () => {
//       if (currentRef) {
//         observer.unobserve(currentRef);
//       }
//     };
//   }, [handleObserver]);

//   if (!products?.length) {
//     return (
//       <div className="text-center py-8 text-gray-500">
//         Aucun produit trouvé
//       </div>
//     );
//   }

//   return (
//     <div className="space-y-4">
//       {products.map((product, index) => (
//         <motion.div
//           key={`${product.asin}-${index}`}
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ delay: index * 0.05 }}
//         >
//           <ProductCard
//             product={product}
//             uniqueKey={`search-${product.asin}-${index}`}
//           />
//         </motion.div>
//       ))}

//       {(hasNextPage || isFetchingNextPage) && (
//         <div ref={loadMoreRef} className="py-4">
//           {isFetchingNextPage && (
//             <div className="flex justify-center">
//               <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };
import React, { useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { ProductCard } from './ProductCard';
import { Product } from '../types/api';

interface ProductListProps {
  products: Product[];
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: () => void;
}

export const ProductList: React.FC<ProductListProps> = ({
  products,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        hasNextPage &&
        !isFetchingNextPage &&
        fetchNextPage
      ) {
        fetchNextPage();
      }
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '100px',
      threshold: 0.1,
    });

    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [handleObserver]);

  if (!products?.length) {
    return (
      <div className="text-center py-8 text-gray-500">Aucun produit trouvé</div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {products.map((product, index) => (
        <motion.div
          key={`${product.asin}-${index}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.05 }}
        >
          <ProductCard
            product={product}
            uniqueKey={`search-${product.asin}-${index}`}
          />
        </motion.div>
      ))}

      {(hasNextPage || isFetchingNextPage) && (
        <div
          ref={loadMoreRef}
          className="col-span-full py-4 flex justify-center"
        >
          {isFetchingNextPage && (
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          )}
        </div>
      )}
    </div>
  );
};
