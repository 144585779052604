import { useState } from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import {
  StarIcon,
  HeartIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import { Product } from '../types/api';
import { useFavoriteStore } from '../store/favoriteStore';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAmazonLink } from '../utils/affiliateLinks';
import toast from 'react-hot-toast';

interface CustomArrowProps {
  className?: string;
  onClick?: () => void;
  icon: React.ComponentType<{ className: string }>;
}

const CustomArrow: React.FC<CustomArrowProps> = ({ className = '', onClick, icon: Icon }) => (
  <button
    onClick={onClick}
    className={`${className} !w-6 !h-6 !bg-white/90 rounded-full shadow-md flex items-center justify-center hover:bg-white`}
  >
    <Icon className="w-4 h-4 text-gray-600" />
  </button>
);

type AffiliateTagMap = {
  fr: string;
  de: string;
  'co.uk': string;
  es: string;
  it: string;
  'com.be': string;
};


const FLAG_URLS: AffiliateTagMap = {
  fr: 'https://flagicons.lipis.dev/flags/4x3/fr.svg',
  de: 'https://flagicons.lipis.dev/flags/4x3/de.svg',
  'co.uk': 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
  es: 'https://flagicons.lipis.dev/flags/4x3/es.svg',
  it: 'https://flagicons.lipis.dev/flags/4x3/it.svg',
  'com.be': 'https://flagicons.lipis.dev/flags/4x3/be.svg',
};

interface ProductCardProps {
  product: Product;
  uniqueKey?: string;
  compact?: boolean;
}


export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  uniqueKey,
  compact = false,
}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { addFavorite, removeFavorite, isFavorite } = useFavoriteStore();
  const [imageLoaded, setImageLoaded] = useState(false);
  const favorite = isFavorite(product.asin);

  const handleFavoriteClick = async () => {
    if (!currentUser) {
      toast.error('Connectez-vous pour ajouter aux favoris');
      navigate('/login', { state: { from: location.pathname } });
      return;
    }

    try {
      if (favorite) {
        await removeFavorite(product.asin, currentUser.uid);
        toast.success('Retiré des favoris');
      } else {
        await addFavorite(product, currentUser.uid);
        toast.success('Ajouté aux favoris');
      }
    } catch (error) {
      toast.error('Une erreur est survenue');
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    nextArrow: <CustomArrow icon={ChevronRightIcon as React.ComponentType<{ className: string }>} className="" onClick={() => { }} />,
    prevArrow: <CustomArrow icon={ChevronLeftIcon as React.ComponentType<{ className: string }>} className="" onClick={() => { }} />,

  };

  const productImages = (product.imgarrays || [])
    .filter(Boolean)
    .slice(0, 4)
    .map(
      (img) => `https://images-na.ssl-images-amazon.com/images/I/${img}.jpg`
    );

  const defaultImage =
    product.imageUrl || 'https://via.placeholder.com/300?text=No+Image';

  const minPrice = Math.min(
    ...Object.values(product.prices || {})
      .map((price) => price?.fullPrice || Infinity)
      .filter((price) => price > 0)
  );

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImageLoaded(true);
    e.currentTarget.classList.add('loaded');
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = defaultImage;
    setImageLoaded(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`bg-white rounded-xl shadow-lg p-3 h-full flex flex-col ${compact ? 'text-sm' : ''
        }`}
    >
      <div className="flex flex-col h-full">
        <div className="relative w-full pb-[100%] mb-3 bg-white rounded-lg overflow-hidden">
          <div className="absolute inset-0">
            {productImages.length > 0 ? (
              <Slider {...sliderSettings} className="product-carousel h-full">
                {productImages.map((imgUrl, index) => (
                  <div
                    key={`${uniqueKey}-image-${index}`}
                    className="relative pb-[100%]"
                  >
                    <img
                      src={imgUrl}
                      alt={`${product.name} - Image ${index + 1}`}
                      className={`absolute inset-0 w-full h-full object-contain product-image ${!imageLoaded ? 'loading' : ''
                        }`}
                      loading="lazy"
                      onLoad={handleImageLoad}
                      onError={handleImageError}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <img
                src={defaultImage}
                alt={product.name}
                className="w-full h-full object-contain product-image"
                loading="lazy"
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            )}
            <div className="absolute top-2 right-2 flex space-x-2">
              <Tooltip
                title={
                  favorite ? 'Retirer des favoris' : 'Ajouter aux favoris'
                }
              >
                <button
                  onClick={handleFavoriteClick}
                  className="p-2 bg-white/90 backdrop-blur-sm rounded-full shadow hover:bg-white transition-colors"
                >
                  <HeartIcon
                    className={`h-4 w-4 ${favorite ? 'text-red-500' : 'text-gray-400'
                      }`}
                  />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col">
          <h3 className="font-medium text-gray-900 line-clamp-2 mb-2 text-sm">
            {product.name}
          </h3>

          <div className="flex items-center mb-2">
            <StarIcon className="h-3 w-3 text-yellow-400" />
            <span className="ml-1 text-xs text-gray-700">
              {product.rating.toFixed(1)}
            </span>
            <span className="text-xs text-gray-500 ml-1">
              ({product.reviews.toLocaleString()})
            </span>
          </div>

          <div className="grid grid-cols-2 gap-2">
            {Object.entries(product.prices)
              .filter(([_, price]) => price.fullPrice > 0)
              .map(([country, price]) => {
                const isBestPrice = price.fullPrice === minPrice;
                return (
                  <motion.div
                    key={`${uniqueKey}-price-${country}`}
                    whileHover={{ scale: 1.02 }}
                    className={`relative p-2 bg-gray-50 rounded-lg cursor-pointer transition-all duration-200 ${isBestPrice
                      ? 'border-2 border-green-500 shadow-md'
                      : 'hover:shadow-sm'
                      }`}
                  >
                    <div className="flex items-center space-x-1 mb-1">
                      <img
                        src={country in FLAG_URLS ? FLAG_URLS[country as keyof AffiliateTagMap] : ''}
                        alt={country}
                        className="w-4 h-3 object-cover rounded-sm"
                      />
                      {isBestPrice && (
                        <span className="text-[10px] text-green-600 font-medium">
                          Meilleur prix
                        </span>
                      )}
                    </div>
                    <a
                      href={
                        country in FLAG_URLS
                          ? getAmazonLink(product.asin, country as keyof AffiliateTagMap)
                          : getAmazonLink(product.asin, 'fr')
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`text-xs font-bold no-underline transition-colors ${isBestPrice
                        ? 'text-green-600 hover:text-green-700'
                        : 'text-blue-600 hover:text-blue-700'
                        }`}
                    >
                      {price.fullPrice.toFixed(2)} {price.currencySymbol}
                    </a>
                  </motion.div>
                );
              })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};