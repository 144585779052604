import axios from 'axios';
import { SearchResult} from '../types/api';

const searchApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const popularDealsApi = axios.create({
  baseURL:
    'https://api.allorigins.win/raw?url=' +
    encodeURIComponent('https://popular-deals.vercel.app'),
  timeout: 10000,
});

const PAGE_OFFSETS = [0, 11, 23, 35, 47];

export const searchProducts = async (
  query: string,
  pageIndex = 0,
  limit = 12
): Promise<SearchResult[]> => {
  try {
    const offset = PAGE_OFFSETS[pageIndex];
    if (offset === undefined) {
      return [];
    }

    const locales = ['com.be', 'fr', 'de', 'es', 'it', 'co.uk'];
    const { data } = await searchApi.get('/search_products', {
      params: {
        query,
        locales: locales.join(','),
        region: 'fr',
        limit,
        page: offset,
      },
    });

    if (!Array.isArray(data)) {
      console.error('Invalid data structure received from search API');
      return [];
    }

    return data;
  } catch (error) {
    console.error('Error searching products:', error);
    throw error;
  }
};

interface PopularProduct {
  asin: string;
}

export const getPopularProductsAsins = async (): Promise<string[]> => {
  try {
    const { data } = await popularDealsApi.get('/products');
    if (!data?.products) {
      throw new Error('Invalid response format from popular deals API');
    }
    return (data.products as PopularProduct[]).map(product => product.asin).filter(Boolean);
  } catch (error) {
    console.error('Error fetching popular products ASINs:', error);
    return [];
  }
};

export const getMultipleProducts = async (
  asins: string[]
): Promise<SearchResult[]> => {
  try {
    if (!asins.length) return [];

    const locales = ['fr', 'com.be', 'de', 'it', 'es', 'co.uk'];
    const { data } = await searchApi.get('/get_multiple_products', {
      params: {
        asins: asins.join(','),
        locales: locales.join(','),
        region: 'fr',
      },
    });

    if (!data?.productsData || !Array.isArray(data.productsData)) {
      console.error(
        'Invalid data structure received from multiple products API'
      );
      return [];
    }

    return data.productsData;
  } catch (error) {
    console.error('Error fetching multiple products:', error);
    return [];
  }
};