import React from 'react';
import { motion } from 'framer-motion';
import { useFavoriteStore } from '../store/favoriteStore';
import { ChartBarIcon, ArrowTrendingUpIcon, CurrencyEuroIcon } from '@heroicons/react/24/outline';

export const StatsPage: React.FC = () => {
  const favorites = useFavoriteStore((state) => state.favorites);

  const stats = {
    totalFavorites: favorites.length,
    averagePrice: favorites.length > 0
      ? (favorites.reduce((acc, product) => {
          const frPrice = product.prices?.fr?.fullPrice || 0;
          return acc + frPrice;
        }, 0) / favorites.length).toFixed(2)
      : '0.00',
    potentialSavings: favorites.length > 0
      ? favorites.reduce((acc, product) => {
          const prices = Object.values(product.prices || {});
          if (prices.length === 0) return acc;
          const maxPrice = Math.max(...prices.map(p => p.fullPrice));
          const minPrice = Math.min(...prices.map(p => p.fullPrice));
          return acc + (maxPrice - minPrice);
        }, 0).toFixed(2)
      : '0.00'
  };

  return (
    <div>
      <motion.header
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white shadow"
      >
        <div className="max-w-7xl mx-auto py-6 px-4">
          <h1 className="text-3xl font-bold text-gray-900">
            Statistiques
          </h1>
        </div>
      </motion.header>

      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-white rounded-lg shadow p-6"
          >
            <div className="flex items-center">
              <ChartBarIcon className="h-8 w-8 text-blue-500" />
              <h2 className="ml-3 text-xl font-semibold text-gray-900">Produits favoris</h2>
            </div>
            <p className="mt-4 text-3xl font-bold text-blue-600">{stats.totalFavorites}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-lg shadow p-6"
          >
            <div className="flex items-center">
              <CurrencyEuroIcon className="h-8 w-8 text-green-500" />
              <h2 className="ml-3 text-xl font-semibold text-gray-900">Prix moyen</h2>
            </div>
            <p className="mt-4 text-3xl font-bold text-green-600">{stats.averagePrice} €</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-white rounded-lg shadow p-6"
          >
            <div className="flex items-center">
              <ArrowTrendingUpIcon className="h-8 w-8 text-purple-500" />
              <h2 className="ml-3 text-xl font-semibold text-gray-900">Économies potentielles</h2>
            </div>
            <p className="mt-4 text-3xl font-bold text-purple-600">{stats.potentialSavings} €</p>
          </motion.div>
        </div>

        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Conseils d'économies</h2>
          <div className="bg-white rounded-lg shadow p-6">
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="flex-shrink-0 h-6 w-6 text-green-500">✓</span>
                <p className="ml-3 text-gray-600">Comparez les prix entre différents pays pour maximiser vos économies.</p>
              </li>
              <li className="flex items-start">
                <span className="flex-shrink-0 h-6 w-6 text-green-500">✓</span>
                <p className="ml-3 text-gray-600">Tenez compte des frais de livraison dans votre comparaison.</p>
              </li>
              <li className="flex items-start">
                <span className="flex-shrink-0 h-6 w-6 text-green-500">✓</span>
                <p className="ml-3 text-gray-600">Surveillez les variations de prix pour acheter au meilleur moment.</p>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};