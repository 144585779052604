import { Product, SearchResult } from '../types/api';

export function transformSearchResults(results: SearchResult[]): Product[] {
  if (!Array.isArray(results)) {
    console.warn('Invalid results array:', results);
    return [];
  }

  const transformedProducts = results
    .map(result => {
      try {
        if (!result?.asin || !Array.isArray(result.productData)) {
          console.warn('Invalid result object:', result);
          return null;
        }

        const prices: Product['prices'] = {};
        
        result.productData.forEach(data => {
          if (data?.country) {
            prices[data.country] = {
              price: Number(data.price) || 0,
              deliveryPrice: Number(data.deliveryPrice) || 0,
              fullPrice: Number(data.fullPrice) || Number(data.price) || 0,
              currencySymbol: data.currencySymbol || '€'
            };
          }
        });

        const firstProduct = result.productData[0];
        if (!firstProduct?.name) {
          console.warn('Missing product name for ASIN:', result.asin);
          return null;
        }

        const imgarrays = firstProduct.imgarrays?.filter(Boolean) || [];
        const imageUrl = imgarrays[0] 
          ? `https://images-na.ssl-images-amazon.com/images/I/${imgarrays[0]}.jpg`
          : undefined;

        const product: Product = {
          asin: result.asin,
          name: firstProduct.name,
          brand: firstProduct.brand || firstProduct.name.split(' ')[0],
          prices,
          rating: Number(firstProduct.productRating) || 0,
          reviews: Number(firstProduct.reviews) || 0,
          imageUrl,
          imgarrays
        };

        return product;
      } catch (error) {
        console.error('Error transforming product:', error, result);
        return null;
      }
    })
    .filter((product): product is Product => product !== null);

  return transformedProducts;
}