import { 
  collection, 
  doc, 
  setDoc, 
  deleteDoc, 
  getDocs, 
  query, 
  where,
  onSnapshot,
  serverTimestamp
} from 'firebase/firestore';
import { db } from './config';
import { Product } from '../../types/api';
import { useFavoriteStore } from '../../store/favoriteStore';

let unsubscribeFavorites: (() => void) | null = null;

// Favorites
export const addToFavorites = async (userId: string, product: Product) => {
  const docRef = doc(db, 'favorites', `${userId}_${product.asin}`);
  await setDoc(docRef, {
    userId,
    product,
    createdAt: serverTimestamp()
  });
};

export const removeFromFavorites = async (userId: string, asin: string) => {
  const docRef = doc(db, 'favorites', `${userId}_${asin}`);
  await deleteDoc(docRef);
};

export const getFavorites = async (userId: string) => {
  const q = query(collection(db, 'favorites'), where('userId', '==', userId));
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => doc.data().product as Product);
};

// Data synchronization
export const syncUserData = async (userId: string) => {
  try {
    // Cleanup previous listeners
    cleanupUserData();

    // Sync favorites
    const favoritesQuery = query(
      collection(db, 'favorites'), 
      where('userId', '==', userId)
    );
    
    unsubscribeFavorites = onSnapshot(favoritesQuery, (snapshot) => {
      const favorites = snapshot.docs.map(doc => doc.data().product as Product);
      useFavoriteStore.getState().setFavorites(favorites);
    });

  } catch (error) {
    console.error('Error setting up data sync:', error);
    throw error;
  }
};

export const cleanupUserData = () => {
  if (unsubscribeFavorites) {
    unsubscribeFavorites();
    unsubscribeFavorites = null;
  }
  
  // Clear local stores
  useFavoriteStore.getState().setFavorites([]);
};