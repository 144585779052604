import React, { useState, useEffect, Suspense } from 'react';
import { motion } from 'framer-motion';
import { SearchBar } from '../components/SearchBar';
import { ProductList } from '../components/ProductList';
import { ErrorMessage } from '../components/ErrorMessage';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { BrandLogos } from '../components/BrandLogos';
import { PopularProducts } from '../components/PopularProducts';
import { useProducts } from '../hooks/useProducts';
import { usePopularProductsStore } from '../store/popularProductsStore';
import { Product } from '../types/api';

const MemoizedBrandLogos = React.memo(BrandLogos);

export const HomePage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const { fetchProducts } = usePopularProductsStore();

  const {
    data,
    isLoading,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useProducts(searchTerm);

  useEffect(() => {
    const initializeProducts = async () => {
      try {
        await fetchProducts();
      } catch (err) {
        console.error('Error fetching popular products:', err);
      }
    };
    initializeProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (data && searchTerm.trim() !== '') {
      setAllProducts(data.pages.flat());
    } else {
      setAllProducts([]);
    }
  }, [data, searchTerm]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pb-20">
      <motion.header
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white/95 backdrop-blur-sm shadow-sm sticky top-0 z-50"
      >
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center space-y-4">
            {/* Logo avec alt décrivant l’image */}
            <img 
              src="https://tastech.fr/wp-content/uploads/2024/11/k-mpar-high-resolution-logo-transparent.png" 
              alt="Logo de Komparzon, comparateur de prix Amazon en Europe" 
              className="h-10 w-auto"
              width="200"
              height="40"
              loading="eager"
            />
            {/* Titre principal de la page (h1) */}
            <h1 className="text-xl font-bold text-gray-900 text-center">
              Comparez les prix Amazon en Europe
            </h1>
          </div>
        </div>
      </motion.header>

      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">

        <Suspense fallback={<LoadingSpinner />}>
          <motion.section
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-6"
          >
            {/* Barre de recherche */}
            <SearchBar onSearch={setSearchTerm} isLoading={isLoading} />
          </motion.section>

          {searchTerm.trim() === '' ? (
            // Section affichée quand aucune recherche n’est effectuée
            <motion.section
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <div className="space-y-10">
                <div className="text-center mb-8">
                  {/* H2 pour marquer cette section comme un sous-titre du site */}
                  <h2 className="text-2xl font-bold text-gray-900 mb-3">
                    Découvrez les meilleures offres
                  </h2>
                  <p className="text-sm text-gray-600">
                    Komparzon vous permet de comparer les prix de produits Amazon 
                    entre 6 pays européens. Trouvez rapidement la meilleure offre 
                    et économisez sur vos achats en ligne.
                  </p>
                </div>

                {/* Logos de marques ou de pays (brand logos) */}
                <MemoizedBrandLogos />

                {/* Section des produits populaires, avec un heading adapté */}
                <article className="bg-white rounded-xl shadow-lg p-4 sm:p-6">
                  <h3 className="text-xl font-semibold text-gray-800 mb-4">Produits populaires</h3>
                  <p className="text-sm text-gray-600 mb-4">
                    Voici une sélection des produits les plus populaires du moment. Comparez les prix, lisez les avis et faites le meilleur choix.
                  </p>
                  <PopularProducts />
                </article>
              </div>
            </motion.section>
          ) : (
            // Section affichée lorsque l'utilisateur effectue une recherche
            <motion.section
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-6"
            >
              {typeof error === 'string' && (
                <ErrorMessage message={error} />
              )}

              {isLoading && <LoadingSpinner />}

              {allProducts.length > 0 && (
                <article className="bg-white rounded-xl shadow-lg p-4 sm:p-6">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">
                    Résultats de la recherche
                  </h2>
                  <p className="text-sm text-gray-600 mb-4">
                    Vous recherchez : <strong>{searchTerm}</strong>. 
                    Comparez les prix entre différents pays européens et trouvez la meilleure affaire.
                  </p>
                  {/* Liste de produits avec potentiellement images lazy load et alt text
                     Assurez-vous que dans ProductList et le composant produit, les images ont `alt` descriptif. */}
                  <ProductList
                    products={allProducts}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    fetchNextPage={fetchNextPage}
                  />
                </article>
              )}
            </motion.section>
          )}
        </Suspense>
      </main>
    </div>
  );
};
