import React from 'react';
import { motion } from 'framer-motion';
import { ProductList } from '../components/ProductList';
import { useFavoriteStore } from '../store/favoriteStore';

export const FavoritesPage: React.FC = () => {
  const favorites = useFavoriteStore((state) => state.favorites);

  return (
    <div>
      <motion.header
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-white shadow"
      >
        <div className="max-w-7xl mx-auto py-6 px-4">
          <h1 className="text-3xl font-bold text-gray-900">
            Mes Favoris
          </h1>
        </div>
      </motion.header>

      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {favorites.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-500">Aucun favori pour le moment</p>
          </div>
        ) : (
          <ProductList products={favorites} />
        )}
      </main>
    </div>
  );
};