// import React, { useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { ProductCard } from './ProductCard';
// import { LoadingSpinner } from './LoadingSpinner';
// import { ErrorMessage } from './ErrorMessage';
// import { usePopularProductsStore } from '../store/popularProductsStore';

// export const PopularProducts: React.FC = () => {
//   const { products, loading, error, fetchProducts, lastUpdated } = usePopularProductsStore();

//   useEffect(() => {
//     const now = Date.now();
//     // Fetch if no data or data is older than 6 hours
//     if (!lastUpdated || now - lastUpdated > 6 * 60 * 60 * 1000) {
//       fetchProducts().catch(error => {
//         console.error('Failed to fetch popular products:', error);
//       });
//     }
//   }, [fetchProducts, lastUpdated]);

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-48">
//         <LoadingSpinner />
//       </div>
//     );
//   }

//   if (error) {
//     return <ErrorMessage message={error} />;
//   }

//   if (!products.length) {
//     return (
//       <div className="text-center py-8 text-gray-500">
//         Aucun produit populaire disponible pour le moment
//       </div>
//     );
//   }

//   return (
//     <section>
//       <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
//         Produits populaires
//       </h2>
//       <div className="grid grid-cols-2 gap-4 sm:gap-6">
//         {products.map((product, index) => (
//           <motion.div
//             key={`popular-${product.asin}-${index}`}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ delay: index * 0.1 }}
//             className="flex flex-col h-full"
//           >
//             <ProductCard
//               product={product}
//               uniqueKey={`popular-${product.asin}-${index}`}
//               compact={true}
//             />
//           </motion.div>
//         ))}
//       </div>
//     </section>
//   );
// };

import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { ProductCard } from './ProductCard';
import { LoadingSpinner } from './LoadingSpinner';
import { ErrorMessage } from './ErrorMessage';
import { usePopularProductsStore } from '../store/popularProductsStore';

export const PopularProducts: React.FC = () => {
  const { products, loading, error, fetchProducts, lastUpdated } =
    usePopularProductsStore();

  useEffect(() => {
    const now = Date.now();
    // Fetch if no data or data is older than 6 hours
    if (!lastUpdated || now - lastUpdated > 6 * 60 * 60 * 1000) {
      fetchProducts().catch((error) => {
        console.error('Failed to fetch popular products:', error);
      });
    }
  }, [fetchProducts, lastUpdated]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-48">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!products.length) {
    return (
      <div className="text-center py-8 text-gray-500">
        Aucun produit populaire disponible pour le moment
      </div>
    );
  }

  return (
    <section>
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
        Produits populaires
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
        {products.map((product, index) => (
          <motion.div
            key={`popular-${product.asin}-${index}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="flex flex-col h-full"
          >
            <ProductCard
              product={product}
              uniqueKey={`popular-${product.asin}-${index}`}
              compact={true}
            />
          </motion.div>
        ))}
      </div>
    </section>
  );
};
