import { Link, useLocation } from 'react-router-dom';
import { 
  HomeIcon, 
  HeartIcon, 
  ChartBarIcon,
  ArrowRightOnRectangleIcon,
  ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';

export const Navigation: React.FC = () => {
  const location = useLocation();
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Déconnexion réussie');
    } catch (error) {
      toast.error('Erreur lors de la déconnexion');
    }
  };

  const publicTabs = [
    { path: '/', icon: HomeIcon, label: 'Accueil' },
    { path: '/stats', icon: ChartBarIcon, label: 'Stats' },
  ];

  const privateTabs = [
    { path: '/favorites', icon: HeartIcon, label: 'Favoris' }
  ];

  const tabs = currentUser ? [...publicTabs, ...privateTabs] : publicTabs;

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50">
      <div className="max-w-md mx-auto px-2">
        <div className="flex justify-around py-2">
          {tabs.map(({ path, icon: Icon, label }) => (
            <Link
              key={path}
              to={path}
              className="relative px-2 py-1"
            >
              <div className="flex flex-col items-center">
                <Icon className={`h-6 w-6 ${location.pathname === path ? 'text-blue-500' : 'text-gray-500'}`} />
                <span className="text-[10px] mt-0.5 font-medium">{label}</span>
                {location.pathname === path && (
                  <motion.div
                    layoutId="bubble"
                    className="absolute -bottom-2 left-0 right-0 h-0.5 bg-blue-500"
                    initial={false}
                    transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                  />
                )}
              </div>
            </Link>
          ))}
          
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="relative px-2 py-1"
            >
              <div className="flex flex-col items-center">
                <ArrowRightOnRectangleIcon className="h-6 w-6 text-gray-500" />
                <span className="text-[10px] mt-0.5 font-medium">Déconnexion</span>
              </div>
            </button>
          ) : (
            <Link
              to="/login"
              className="relative px-2 py-1"
            >
              <div className="flex flex-col items-center">
                <ArrowLeftOnRectangleIcon className="h-6 w-6 text-gray-500" />
                <span className="text-[10px] mt-0.5 font-medium">Connexion</span>
              </div>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};