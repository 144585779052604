import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Product } from '../types/api';
import { addToFavorites as addToFirestore, removeFromFavorites as removeFromFirestore } from '../services/firebase/firestore';

interface FavoriteStore {
  favorites: Product[];
  setFavorites: (favorites: Product[]) => void;
  addFavorite: (product: Product, userId: string) => Promise<void>;
  removeFavorite: (asin: string, userId: string) => Promise<void>;
  isFavorite: (asin: string) => boolean;
}

export const useFavoriteStore = create<FavoriteStore>()(
  persist(
    (set, get) => ({
      favorites: [],
      setFavorites: (favorites) => set({ favorites }),
      addFavorite: async (product, userId) => {
        try {
          await addToFirestore(userId, product);
          set((state) => ({
            favorites: [...state.favorites, product]
          }));
        } catch (error) {
          console.error('Error adding favorite:', error);
          throw error;
        }
      },
      removeFavorite: async (asin, userId) => {
        try {
          await removeFromFirestore(userId, asin);
          set((state) => ({
            favorites: state.favorites.filter((p) => p.asin !== asin)
          }));
        } catch (error) {
          console.error('Error removing favorite:', error);
          throw error;
        }
      },
      isFavorite: (asin) => get().favorites.some((p) => p.asin === asin),
    }),
    {
      name: 'favorites-storage',
      version: 1,
      storage: createJSONStorage(() => localStorage)
    }
  )
);