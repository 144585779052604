import { useInfiniteQuery } from 'react-query';
import { searchProducts } from '../services/api';
import { Product, SearchResult } from '../types/api';
import { transformSearchResults } from '../utils/transformers';
import { UseInfiniteQueryResult } from 'react-query';

type UseProductsReturn = Omit<UseInfiniteQueryResult<Product[], unknown>, 'error'> & {
  error: string | null;
};
const PAGE_OFFSETS = [0, 11, 23, 35, 47];

export function useProducts(searchTerm: string): UseProductsReturn {
  const query = useInfiniteQuery(
    ['products', searchTerm],
    async ({ pageParam = 0 }) => searchProducts(searchTerm, pageParam),
    {
      enabled: !!searchTerm,
      getNextPageParam: (_, allPages) => {
        const nextPageIndex = allPages.length;
        return nextPageIndex < PAGE_OFFSETS.length ? nextPageIndex : undefined;
      },
      select: (data) => ({
        pages: data.pages.map((page: SearchResult[]): Product[] =>
          transformSearchResults(page)
        ),
        pageParams: data.pageParams,
      }),
      retry: 2,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const error = query.error
    ? query.error instanceof Error
      ? query.error.message
      : String(query.error)
    : null;

  return {
    ...query,
    error,
  };
}
