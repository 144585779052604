import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Product } from '../types/api';
import { fetchPopularProducts } from '../services/popularProducts';

interface PopularProductsState {
  products: Product[];
  loading: boolean;
  error: string | null;
  lastUpdated: number | null;
  fetchProducts: () => Promise<void>;
}

export const usePopularProductsStore = create<PopularProductsState>()(
  persist(
    (set) => ({
      products: [],
      loading: false,
      error: null,
      lastUpdated: null,
      fetchProducts: async () => {
        try {
          set({ loading: true, error: null });
          const products = await fetchPopularProducts();
          
          set({ 
            products,
            loading: false,
            lastUpdated: Date.now(),
            error: products.length === 0 ? 'Aucun produit populaire disponible' : null
          });
        } catch (error) {
          console.error('Error fetching popular products:', error);
          set({ 
            error: 'Erreur lors de la récupération des produits populaires',
            loading: false,
            lastUpdated: Date.now()
          });
        }
      }
    }),
    {
      name: 'popular-products-storage',
      version: 1,
      storage: createJSONStorage(() => localStorage)
    }
  )
);